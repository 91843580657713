$color-error-500: #f04438;
$color-success: #27ae60;

//v1 colors
$color-white: #fff;
$color-black: #000;
$color-elephant: #142e48;
$color-golden-fizz: #e3ff37;
$color-violet-red: #ed2487;
$color-shuttle-gray: #5a6d7f;
$color-cornflower-blue: #63b3ed;
$color-dark-gray: #3f4041;
$color-light-gray: #d7dee6;
$color-lightest-gray: #bcbfc3;
$color-light-blue: rgba(23, 95, 166, 0.08);

//colors from :root variables
$color-brand-primary: (var(--color-brand-primary));
$color-brand-secondary: (var(--color-brand-secondary));
$color-brand-secondary-dark: (var(--color-brand-secondary-dark));
$color-brand-secondary-light: (var(--color-brand-secondary-light));
$color-brand-cta: (var(--color-brand-cta));
$color-brand-cta-dark: (var(--color-brand-cta-dark));
$color-brand-cta-light: (var(--color-brand-cta-light));

$color-black: var(--color-black);
$color-gray-900: var(--color-gray-900);
$color-gray-800: var(--color-gray-800);
$color-gray-700: var(--color-gray-700);
$color-gray-600: var(--color-gray-600);
$color-gray-500: var(--color-gray-500);
$color-gray-400: var(--color-gray-400);
$color-gray-300: var(--color-gray-300);
$color-gray-200: var(--color-gray-200);
$color-gray-100: var(--color-gray-100);
$color-gray-50: var(--color-gray-50);
$color-white: var(--color-white);
