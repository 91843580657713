//default videostamps brand color vars
:root {
  --color-brand-primary: #142e48;
  --color-brand-secondary: #eb308c;
  --color-brand-secondary-dark: #be2872;
  --color-brand-secondary-light: #fcbfdd;
  --color-brand-cta: var(--color-brand-secondary);
  --color-brand-cta-dark: var(--color-brand-secondary-dark);
  --color-brand-cta-light: var(--color-brand-secondary-dark);

  --color-black: #000;
  --color-gray-900: #101828;
  --color-gray-800: #1d2939;
  --color-gray-700: #344054;
  --color-gray-600: #475467;
  --color-gray-500: #667085;
  --color-gray-400: #98a2b3;
  --color-gray-300: #d0d5dd;
  --color-gray-200: #eaecf0;
  --color-gray-100: #f2f4f7;
  --color-gray-50: #f9fafb;
  --color-white: #fff;

  --header-logo-height: 51px;

  --font-family: 'sofia-pro', sans-serif;
}
