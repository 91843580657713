//font-family: 'Nunito Sans'
@import '@fontsource/nunito-sans/300';
@import '@fontsource/nunito-sans/400';
@import '@fontsource/nunito-sans/700';
@import '@fontsource/nunito-sans/800';

//font-family: 'Inter'
@import '@fontsource/inter/400';
@import '@fontsource/inter/600';
@import '@fontsource/inter/700';

//font-family: 'Barlow'
@import '@fontsource/barlow/400';
@import '@fontsource/barlow/700';

//font-family: 'Barlow Semi Condensed'
@import '@fontsource/barlow-semi-condensed/400';
@import '@fontsource/barlow-semi-condensed/700';

//App template fonts
@font-face {
  font-family: 'quattrocento';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/quattrocento/quattrocento-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'quattrocento';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/quattrocento/quattrocento-bold.ttf) format('truetype');
}
