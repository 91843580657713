@use 'sass:color';
@import '@styles/colors';
@import '@styles/mixins';

.vs-btn {
  align-items: center;
  border-radius: 0.5rem;
  border: 0px solid transparent;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  font-size: pxToRem(16px);
  font-weight: 700;
  justify-content: center;
  line-height: pxToRem(24px);
  height: pxToRem(48px);
  padding-top: pxToRem(11px);
  padding-bottom: pxToRem(13px);
  outline: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
  }
}

//variants
.vs-btn-primary {
  background-color: $color-brand-secondary;
  color: $color-white;

  &:active {
    background-color: $color-brand-secondary-dark;
  }

  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fde9f3;
  }

  &:disabled {
    background-color: $color-brand-secondary-light;
  }
}

.vs-btn-secondary {
  background-color: $color-white;
  border: 1px solid $color-gray-300;
  color: $color-gray-700;

  &:active {
    background-color: $color-gray-50;
    color: $color-gray-800;
  }

  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
  }

  &:disabled {
    border: 1px solid $color-gray-200;
    color: $color-gray-300;
  }
}

.vs-btn-tertiary {
  background-color: transparent;
  box-shadow: none;
  color: $color-brand-secondary;

  &:active {
    background-color: $color-gray-50;
    color: $color-gray-800;
  }

  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4f7;
  }

  &:disabled {
    border: 1px solid $color-gray-200;
    color: $color-gray-300;
  }
}

.vs-btn-cta {
  background-color: $color-brand-cta;
  color: $color-white;

  &:active {
    background-color: $color-brand-cta-dark;
  }

  &:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #fde9f3;
  }

  &:disabled {
    background-color: $color-brand-cta-light;
  }
}
