@import '@styles/mixins';
@import '@styles/colors';

$spinner-duration: 1s;
$spinner-size-lg: pxToRem(90px);
$spinner-size-md: pxToRem(64px);
$spinner-size-sm: pxToRem(24px);

.vs-spinner {
  &__wrapper {
    padding: pxToRem(6px);
  }

  &__container {
    margin: auto;
    position: relative;
    width: $spinner-size-lg;
    height: $spinner-size-lg;

    & div {
      transform-origin: calc($spinner-size-lg / 2) calc($spinner-size-lg / 2);
      animation: vs-spinner $spinner-duration linear infinite;
    }

    & div:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: calc($spinner-size-lg / 2 - 2px);
      width: 4px;
      height: calc($spinner-size-lg / 5);
      border-radius: 99rem;
    }

    & div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: calc($spinner-duration / 8 * 0);
    }

    & div:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: calc($spinner-duration / 8 * 1);
    }
    & div:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: calc($spinner-duration / 8 * 2);
    }
    & div:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: calc($spinner-duration / 8 * 3);
    }
    & div:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: calc($spinner-duration / 8 * 4);
    }
    & div:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: calc($spinner-duration / 8 * 5);
    }
    & div:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: calc($spinner-duration / 8 * 6);
    }
    & div:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: calc($spinner-duration / 8 * 7);
    }
  }

  //sizes
  &--small {
    width: $spinner-size-sm;
    height: $spinner-size-sm;
  }

  &--small div {
    transform-origin: calc($spinner-size-sm / 2) calc($spinner-size-sm / 2);
  }

  &--small div:after {
    left: calc($spinner-size-sm / 2 - 1px);
    width: 2px;
    height: calc($spinner-size-sm / 5);
  }

  &--medium {
    width: $spinner-size-md;
    height: $spinner-size-md;
  }

  &--medium div {
    transform-origin: calc($spinner-size-md / 2) calc($spinner-size-md / 2);
  }

  &--medium div:after {
    left: calc($spinner-size-md / 2 - 1px);
    width: 2px;
    height: calc($spinner-size-md / 5);
  }

  //colors
  &--primary div:after {
    background-color: $color-brand-primary;
  }
  &--secondary div:after {
    background-color: $color-white;
  }
  &--accent div:after {
    background-color: $color-brand-secondary;
  }
}

@keyframes vs-spinner {
  0% {
    height: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
