@import '@styles/mixins';

.vs-header {
  min-height: 2rem;

  img {
    height: var(--header-logo-height);
    margin: 1rem 2rem 2rem;
    object-fit: contain;
    width: auto;
  }
}
