@import './template-css-vars.scss'; //app template vars

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './colors.scss'; //css color vars in scss form
@import './fonts.scss';

html {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: var(--font-family);
  font-weight: 400;
  font-style: normal;
  height: 100%;
  font-size: 100%; //16px

  @media only screen and (max-width: 320px) and (orientation: portrait),
    only screen and (max-height: 290px) and (orientation: landscape) {
    font-size: 93.75%; //15px
  }
  @media only screen and (max-width: 290px) and (orientation: portrait),
    only screen and (max-height: 260px) and (orientation: landscape) {
    font-size: 87.5%; //14 px
  }
  @media only screen and (max-width: 260px) and (orientation: portrait),
    only screen and (max-height: 230px) and (orientation: landscape) {
    font-size: 75%; //12px
  }
  @media only screen and (max-width: 230px) and (orientation: portrait),
    only screen and (max-height: 200px) and (orientation: landscape) {
    font-size: 62.5%; //10px
  }
  @media only screen and (max-width: 200px) and (orientation: portrait),
    only screen and (max-height: 170px) and (orientation: landscape) {
    font-size: 56.25%; //9px
  }
}

body {
  background-color: $color-white;
  color: $color-black;
  height: 100%;
  position: fixed;
  width: 100%;
}

#rot-root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.tap-none {
  -webkit-tap-highlight-color: transparent;
}

.mirror-horizontal {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

.z-fix-ios {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
