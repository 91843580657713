@import '@styles/colors';
@import '@styles/mixins';

.modal-overlay {
  align-items: center;
  background-color: rgba($color-black, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.modal-content {
  background-color: $color-white;
  min-width: 200px;
  margin: pxToRem(24px);
  padding: pxToRem(44px) pxToRem(40px) pxToRem(24px);
  position: relative;
}
